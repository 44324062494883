import { getBucketUrl } from '../../config';

type GetImageByLinkParams =
  | { bucketName: 'pets-sale-bucket'; imageName: string | null; subPath: string }
  | { bucketName: 'pet-avatars' | 'dogs-blog-bucket'; imageName: string | null; subPath?: never };

export const getImageByLink = async (params: GetImageByLinkParams) => {
  try {
    const { bucketName, imageName, subPath } = params;

    if (!imageName) {
      return Response.json(`${imageName} is not exist.`, { status: 500 });
    }

    const imageUrl = getBucketUrl(bucketName, imageName, subPath);

    return Response.json({ imageUrl: decodeURI(imageUrl) });
  } catch (error) {
    return Response.json(error, { status: 500 });
  }
};
