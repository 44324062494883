import { upperFirst } from 'lodash-es';
import NextImage from 'next/image';
import React, { useState } from 'react';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { blurDataURL, classNames } from '@/shared/lib';

import 'swiper/css';

interface PublicationImageCarouselProps {
  pictures: string[];
  publicationType: 'продажа' | 'дарение' | 'случка';
  pubTypeBgColorLookup: Record<string, string>;
  currentImageIndex: number;
  handleImageCurrentIndex: (index: number) => void;
  imageErrors: boolean[];
  onImageError: (index: number) => void;
}

const PublicationImageCarousel: React.FC<PublicationImageCarouselProps> = ({
  pictures,
  publicationType,
  pubTypeBgColorLookup,
  currentImageIndex,
  handleImageCurrentIndex,
  onImageError,
  imageErrors
}) => {
  const [isSwiperInitialized, setIsSwiperInitialized] = useState(false);

  const handleInitializeSwiper = () => {
    if (!isSwiperInitialized) {
      setIsSwiperInitialized(true);
    }
  };

  return (
    <div className="relative flex">
      {!isSwiperInitialized && (
        <div className="relative h-[180px] w-full md:h-[280px]">
          <NextImage
            src={imageErrors[0] ? '/broken-image.svg' : blurDataURL}
            alt={`carousel-image-mobile-0`}
            fill
            sizes="(max-width: 768px) 5vw, 60vw"
            priority
            className={classNames(
              'relative h-[180px] w-full rounded-t-2xl object-cover transition-opacity duration-500 ease-in-out md:h-[280px] md:rounded-t-4xl',
              imageErrors[0] ? 'bg-slate-400' : ''
            )}
            decoding="async"
            onError={() => onImageError(0)}
            onLoad={handleInitializeSwiper}
          />
        </div>
      )}
      {isSwiperInitialized && (
        <Swiper
          modules={[Virtual]}
          onSlideChange={(swiper) => handleImageCurrentIndex(swiper.activeIndex)}
          initialSlide={currentImageIndex}
          className="h-[180px] w-full md:h-[280px]"
        >
          {pictures.map((picture, index) => (
            <SwiperSlide key={`swiper-slide-grid-${index}`} className="flex h-full w-full">
              <NextImage
                src={imageErrors[index] ? '/broken-image.svg' : picture}
                alt={`carousel-image-mobile-${index}`}
                fill
                priority={index === 0}
                fetchPriority={index === 0 ? 'high' : undefined}
                loading={index === 0 ? 'eager' : 'lazy'}
                sizes="(max-width: 768px) 15vw, 50vw"
                className={classNames(
                  'h-[180px] rounded-t-2xl object-cover transition-opacity duration-500 ease-in-out md:h-[280px] md:rounded-t-4xl',
                  imageErrors[index] ? 'bg-slate-400' : ''
                )}
                decoding={index === 0 ? 'async' : 'sync'}
                onError={() => onImageError(index)}
              />
            </SwiperSlide>
          ))}
          <div
            className={`${pubTypeBgColorLookup[publicationType]} absolute inset-y-[82%] left-2 z-20 h-6 rounded-full md:left-5 md:h-8`}
          >
            {!!publicationType && (
              <p className="px-2 text-sm leading-[1.3rem] text-white md:px-4 md:leading-8">
                {upperFirst(publicationType)}
              </p>
            )}
          </div>
        </Swiper>
      )}
    </div>
  );
};

export default PublicationImageCarousel;
